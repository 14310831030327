import axios from 'axios'

export default {
	state: {
		yessPartners: [],
		currentYessPartnerDiscounts: [],
		gettingYessPartners: false,
		gettingCurrentYessPartners: false,
		savingYessPartnerDiscounts: false,
    	defaultNamespace: 'yess'
	},
	mutations: {
		updateYessPartners(state, partners) {
			state.yessPartners = partners
		},
		updateCurrentYessPartnerDiscounts(state, partners) {
			state.currentYessPartnerDiscounts = partners
		},
		updateSavingYessPartnerDiscounts(state, status) {
			state.savingYessPartnerDiscounts = status
		},
		updateGettingCurrentYessPartners(state, status) {
			state.gettingCurrentYessPartners = status
		},
		updateGettingYessPartners(state, status) {
			state.gettingYessPartners = status
		}
	},
	actions: {
		getYessPartners({ state, commit, getters }) {
			commit('updateGettingYessPartners', true)

      let namespace = state.defaultNamespace

			let body = {
				query: 'query YessPartners($namespace: String) {\n' +
					'   yessPartners(namespace: $namespace) {\n' +
					'       uuid\n' +
					'       label\n' +
					'   }\n' +
					'}',
        variables: {
          namespace: namespace
        }
			}

			return axios.post(getters.graphUrl, body).then(response => {
				let yessPartners = response.data.data.yessPartners
				for(let i = 0; i < yessPartners.length; i++) {
					let yessPartner = yessPartners[i]
					yessPartner.discountPercent = null
				}
				commit('updateYessPartners', yessPartners)
				return yessPartners
			}).finally(() => {
				commit('updateGettingYessPartners', false)
			})
		},
		getCurrentYessPartnerDiscounts({ state, commit, getters }) {
			commit('updateGettingCurrentYessPartners', true)

      let namespace = state.defaultNamespace

			let body = {
				query: 'query YessPartnerDiscounts($organization: String, $partner: String, $namespace: String) {\n' +
					'   yessPartnerDiscounts(organization: $organization, partner: $partner, namespace: $namespace){\n' +
					'       discountPercent' +
					'       partner{' +
					'           uuid' +
					'           label' +
					'       }' +
					'       organization{' +
					'           uuid' +
					'           label' +
					'       }' +
					'   }\n' +
					'}',
				variables: {
					organization: getters.organization.uuid,
					partner: null,
          namespace: namespace
				}
			}

			return axios.post(getters.graphUrl, body).then(response => {
				let yessPartnerDiscountsResponse = response.data.data.yessPartnerDiscounts
				for(let i = 0; i < yessPartnerDiscountsResponse.length; i++) {
					let partner = yessPartnerDiscountsResponse[i]

					let updatePartner = state.yessPartners.find(p => p.uuid === partner.partner.uuid)
					if(updatePartner) {
						updatePartner.discountPercent = partner.discountPercent
					}
				}

				commit('updateCurrentYessPartnerDiscounts', yessPartnerDiscountsResponse)
				return yessPartnerDiscountsResponse
			}).finally(() => {
				commit('updateGettingCurrentYessPartners', false)
			})
		},
		saveYessPartnerDiscounts({ state, commit, getters }, partners) {
			commit('updateSavingYessPartnerDiscounts', true)

      let namespace = state.defaultNamespace

			let partnerDiscounts = []

			for(let i = 0; i < partners.length; i++) {
				let partner = partners[i]
				let toUpdate = {}
				toUpdate.organization = getters.organization.uuid
				toUpdate.partner = partner.uuid
				toUpdate.discountPercent = partner.discountPercent
        toUpdate.namespace = namespace
				partnerDiscounts.push(toUpdate)
			}

			let body = {
				query: 'mutation SaveYessPartnerDiscounts($partnerDiscounts: [NewYessPartnerDiscount!]!) {\n' +
					'       savePartnerDiscounts(partnerDiscounts: $partnerDiscounts) \n' +
					'}',
				variables: {
					partnerDiscounts: partnerDiscounts
				}
			}

			return axios.post(getters.graphUrl, body).then(response => {
				return response.data.data.savePartnerDiscounts
			}).finally(() => {
				commit('updateSavingYessPartnerDiscounts', false)
			})
		}
	},
	getters: {
		yessPartners: (state) => {
			return state.yessPartners
		},
		currentOrganizationYessPartnerDiscounts: (state) => {
			return state.currentYessPartnerDiscounts
		},
		isGettingCurrentYessPartnerDiscounts: (state) => {
			return state.gettingCurrentYessPartners
		},
		isGettingYessPartners: (state) => {
			return state.gettingYessPartners
		},
		isSavingYessPartnerDiscounts: (state) => {
			return state.savingYessPartnerDiscounts
		}
	}
}
