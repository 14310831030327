import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import i18n from './i18n'
import VueWorker from 'vue-worker'
import VCalendar from 'v-calendar'
import Sticky from 'vue-sticky-directive'
import currencyFormatter from 'currency-formatter'
import '@mdi/font/css/materialdesignicons.css'
import '@/css/style.css'

/* Version */
import { version } from '../package.json'

/* Bugsnag */
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

// Import the Auth0 configuration
import { domain, clientId, responseType, scope } from '../auth_config.json'

// Import the plugin here
import { Auth0Plugin } from './plugins/auth0'

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
	responseType,
	scope,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

Vue.use(Sticky)

import { VueMaskDirective } from 'v-mask'
import './registerServiceWorker'
Vue.directive('mask', VueMaskDirective)

String.prototype.truncate = function(n){
  return (this.length > n) ? this.substr(0, n-1) : this
}

String.prototype.getInitials = function(glue){

  if (typeof glue === 'undefined') {
      glue = true
  }

  var initials = this.split(' ')

  if (glue) {

      let letters = ''

      for(let i in initials) {
        letters += initials[i].charAt(0)
      }

      return letters.toUpperCase().truncate(3)
  }

  return  initials
}

String.prototype.capitalize = function() {
  return this.toLowerCase().replace( /\b\w/g, function (m) {
      return m.toUpperCase()
  })
}

Vue.use(VCalendar, {
  componentPrefix: 'vc',
})

console.info('SalesCloud HQ - Version:', version)

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  appVersion: version
})

Vue.config.productionTip = false

Vue.filter('date', function (timestamp) {
  if (!timestamp) return ''

  const date = new Date(timestamp)
  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
})

Vue.filter('day', function (timestamp) {
  if (!timestamp) return ''

  const date = new Date(timestamp)
  return date.getDay()
})

Vue.filter('price', function (price) {

  if(price === null || price === undefined) {
    return ''
  }

  return currencyFormatter.format(price.amount, { code: price.currency_code })
})

Vue.use(VueWorker)
Vue.use(require('vue-moment'))

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
