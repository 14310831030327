<template>
  <v-menu
    ref="selectDate"
    :return-value.sync="selectedDate"
    v-model="dateMenu"
    :close-on-content-click="false"
    offset-y
    transition="scale-transition"
    :max-width="datePickerWidth"
  >
    <template v-slot:activator="{on, attr}">
      <v-text-field
        readonly
        :label="label"
        :filled="filled"
        :dense="dense"
        v-model="selectedDate"
        :placeholder="$t('pleaseSelectADate')"
        :prepend-icon="noIcon ? '' : (icon ? icon : 'mdi-calendar')"
        v-bind="attr"
        v-on="on"
        :hide-details="hideDetails"
        :disabled="disabled"
        :outlined="outlined"
        :clearable="clearable"
        :persistent-hint="persistentHint"
        :hint="hint"
        @click:clear="clearDate"
      />
    </template>
	<v-date-picker
      v-model="selectedDate"
      v-if="dateMenu"
      no-title
      :full-width="fullWidth"
      :width="width"
      @click:date="$refs.selectDate.save(selectedDate)"
      style="min-height: 350px"
      :min="minDate"
      :max="maxDate"
    />
  </v-menu>
</template>

<script>
export default {
	name: "DateSelection",
	props: {
		value: {
			type: Number,
			default: 0
		},
		label: {
			type: String,
			default: ''
		},
		dense: {
			type: Boolean,
		},
		filled: {
			type: Boolean,
		},
		required: {
			type: Boolean,
		},
		icon: {
			type: String,
			default: ''
		},
		hideDetails: {
			type: Boolean,
		},
		noIcon: {
			type: Boolean,
		},
		disabled: {
			type: Boolean,
		},
		outlined: {
			type: Boolean,
		},
		clearable: {
			type: Boolean,
		},
		noCurrentDayPreload: {
			type: Boolean
		}, 
		minDate: {
			type: String,
			default: ''
		},
		maxDate: {
			type: String,
			default: ''
		}, 
		width: {
			type: Number, 
			default: 350
		},
		fullWidth: {
			type: Boolean,
			default: true
		},
    persistentHint: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null
    }
	},
	data() {
		return {
			selectedDate: null,
			dateMenu: false,
			datePickerWidth: 500,
		}
	},
	mounted() {
		if(this.value !== 0 && this.value !== null && this.value !== undefined) {
			this.selectedDate = new Date(this.value).toISOString().substr(0,10)
		} else {
			if(this.noCurrentDayPreload === false && this.systemTimeInMillis !== 0 && this.systemTimeInMillis !== null && this.systemTimeInMillis !== undefined) {
				this.selectedDate = new Date(this.systemTimeInMillis * 1).toISOString().substr(0,10)
			}
		}
	},
	computed: {
		systemTimeInMillis() {
			return this.$store.getters.systemTime
		}
	},
	methods: {
		clearDate() {
			this.selectedDate = null
		}
	},
	watch: {
		value(dateValue) {
			if(dateValue) {
				if(dateValue !== 0 && dateValue !== null && dateValue !== undefined) {
					this.selectedDate = new Date(dateValue).toISOString().substr(0,10)
				}
			} else {
				if(this.noCurrentDayPreload === false && this.systemTimeInMillis !== 0 && this.systemTimeInMillis !== null && this.systemTimeInMillis !== undefined) {
					this.selectedDate = new Date(this.systemTimeInMillis * 1).toISOString().substr(0,10)
				}
			}
		},
		selectedDate(dateValue) {
			if(dateValue !== 0 && dateValue !== null && dateValue !== undefined) {
				this.$emit('input', new Date(dateValue).getTime())
			} else {
				this.$emit('input', null)
			}
		}
	}
}
</script>

<style scoped>

</style>
