// skipcq: JS-C1003
import * as amplitude from '@amplitude/analytics-browser'

export default {
    actions: {
        // skipcq: JS-0058
        initializeAmplitude: ({ }, { userEmail }) => {
            if(process.env.VUE_APP_AMPLITUDE_API_KEY && userEmail) {
            
                amplitude.init(process.env.VUE_APP_AMPLITUDE_API_KEY, userEmail, {
                    serverZone: 'EU',
                    autocapture: {
                        attribution: false,
                        pageViews: false,
                        sessions: false,
                        formInteractions: false,
                        fileDownloads: false,
                        elementInteractions: false,
                    },
                })
            } else {
                console.error("[amplitude] API key or user email not found", userEmail)
            }
        },
        // skipcq: JS-0058
        trackEvent: ({}, { eventName, eventProperties, userRole }) => {
            try {
                if(process.env.VUE_APP_AMPLITUDE_API_KEY) {
                    const event = {
                        event_type: eventName,
                        event_properties: eventProperties ?? {},
                        groups: userRole ? { 'role': userRole } : {},
                        // group_properties: { 'groupPropertyKey': 'groupPropertyValue' }
                    }
                    
                    amplitude.track(event)
                }
            } catch (error) {
                console.error('[amplitude] error tracking event:', eventName, error)
            }
        }
    }
}