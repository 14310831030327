import axios from 'axios'

export default {
  state: {
    timeSlots: [],
    isGettingTimeSlots: false,
    isUpdatingTimeSlot: false
  },
  mutations: {
    updateTimeSlots(state, timeSlots) {
      state.timeSlots = timeSlots
    },
    updateIsGettingTimeSlots(state, status) {
      state.isGettingTimeSlots = status
    },
    updateIsUpdatingTimeSlot(state, status) {
      state.isUpdatingTimeSlot = status
    }
  },
  actions: {
    getAllTimeSlotsForToday: ({ commit, getters }, payload) => {
      commit('updateIsGettingTimeSlots', true)

      if(payload) {
        console.log(payload)
      }

      const graphUrl = getters.graphUrl

      let body = {}

      return axios.post(graphUrl, body).then(response => {

        let timeSlotResponse = response.data.data.timeSlotsToday
        commit('updateTimeSlots', timeSlotResponse)

      }).finally(() => {
        commit('updateIsGettingTimeSlots', false)
      })
    },
    blockTimeSlot: ({ commit, getters }, payload) => {
      commit('updateIsUpdatingTimeSlot', true)

      let body = {
        query: 'mutation BlockAvailableTimeSlot($startTimeInMillis: Float!, $endTimeInMillis: Float!, $organization: String!, $channel: String!, $location: String!){\n' +
          ' blockAvailableTimeSlot(startTimeInMillis: $startTimeInMillis, endTimeInMillis: $endTimeInMillis, organization: $organization, channel: $channel, location: $location)\n' +
          '}',
        variables: {
          startTimeInMillis: payload.startTimeInMillis,
          endTimeInMillis: payload.endTimeInMillis,
          organization: payload.organization,
          channel: payload.channel,
          location: payload.location
        }
      }

      return axios.post(getters.graphUrl, body).then(response => {
        return response.data.data.blockAvailableTimeSlot
      }).finally(() => {
        commit('updateIsUpdatingTimeSlot', false)
      })
    },
    unblockTimeSlot: ({ commit, getters }, payload) => {
      commit('updateIsUpdatingTimeSlot', true)

      let body = {
        query: 'mutation UnblockAvailableTimeSlot($startTimeInMillis: Float!, $endTimeInMillis: Float!, $organization: String!, $channel: String!, $location: String!){\n' +
          ' unblockAvailableTimeSlot(startTimeInMillis: $startTimeInMillis, endTimeInMillis: $endTimeInMillis, organization: $organization, channel: $channel, location: $location)\n' +
          '}',
        variables: {
          startTimeInMillis: payload.startTimeInMillis,
          endTimeInMillis: payload.endTimeInMillis,
          organization: payload.organization,
          channel: payload.channel,
          location: payload.location
        }
      }

      return axios.post(getters.graphUrl, body).then(response => {
        return response.data.data.unblockAvailableTimeSlot
      }).finally(() => {
        commit('updateIsUpdatingTimeSlot', false)
      })
    }
  },
  getters: {
    timeSlots: (state) => {
      return state.timeSlots
    },
    isGettingTimeSlots: (state) => {
      return state.isGettingTimeSlots
    },
    isUpdatingTimeSlot: (state) => {
      return state.isUpdatingTimeSlot
    }
  }
}
