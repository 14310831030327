<template>
  <v-dialog
    value="true"
    max-width="800px"
    scrollable
    @click:outside="close"
  >
    <v-card class="overflow-x-hidden">
      <v-card-title>{{ $t('exportBuilder') }}</v-card-title>
      <v-card-subtitle>{{ $t('pleaseReviewBeforeExporting') }} {{ exportObjects.length }} {{ $t('rows') }}</v-card-subtitle>

      <v-row>
        <v-col
          cols="12"
          class="py-0"
        >
          <v-card-text>
            <v-select
              filled
              :label="$t('format')"
              :items="exportFormats"
              item-value="key"
              item-text="label"
              v-model="selectedExportFormat"
            />
			<v-switch
				class="mt-0 pt-0"
				v-model="selectAll"
				:label="$t('selectAll')"
			/>
            <v-select
              filled
              :label="$t('columns')"
              multiple
			  :disabled="selectAll"
              :items="columns"
              v-model="selectedColumns"
            />
          </v-card-text>
        </v-col>
      </v-row>

      <v-card-text class="pa-0">
        <v-row class="px-4 mx-0 grey lighten-2">
          <v-col cols="12">
            <div class="text-h6">
              {{ $t('preview') }}
            </div>
          </v-col>
          <v-col cols="12">
            <v-simple-table
              dense
              class="transparent"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <template v-for="(column, index) in selectedColumns">
                      <th
                        :id="index"
                        :key="index"
                      >
                        {{ column }}
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(object, index) in previewObjects">
                    <tr :key="index">
                      <template v-for="(columnValue, index2) in exportColumnValues(object)">
                        <td :key="columnValue + '' + index2">
                          {{ columnValue }}
                        </td>
                      </template>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          block
          :disabled="exporting || selectedColumns.length === 0"
          :loading="exporting"
          color="success"
          large
          @click="exportFile"
        >
          {{ $t('export') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
	name: 'ExportObjectsDialog',
	data: function() {
		return {
			exporting: false,
			dialog: false,
			selectAll: false,
			customer: {},
			customerUUID: null,
			selectedExportFormat: 'xlsx',
			selectedColumns: [],
			columns: []
		}
	},
	computed: {
		exportFormats() {
			return this.$store.state.exportFormats
		},
		exportObjects() {
			const exportObjects = this.$store.state.exportObjects
			const objectToExport = []

			console.log("Lenght of exportObjects array: " + exportObjects.length)

			if(exportObjects === undefined || exportObjects === null) {
				return exportObjects
			}

			for(let i in exportObjects) {

				const exportObject = exportObjects[i]

				if(exportObject === undefined || exportObject === null) {
					continue
				}
				
				objectToExport.push(exportObject)
			}

			return objectToExport
		},
		exportData() {
			let table = '<table>'

			table += this.exportHeaders()
			table += this.exportBody()

			table += '</table>'

			return table
		},
		previewObjects() {
			return this.$store.state.exportObjects.slice(0, 3)
		}
	},
	methods: {
		selectAllProperties() {
			this.selectedColumns = this.columns
		},
		deSelectAllProperties() {
			this.selectedColumns = []
		},
		isFloat(n) {
			return Number(n) === n && n % 1 !== 0
		},
		isInteger(n) {
			return n === +n && n === (n|0)
		},
		close() {
			this.$emit('closed')
		},
		arrayUnique(array) {
			var a = array.concat()
			for(var i=0; i<a.length; ++i) {
				for(var j=i+1; j<a.length; ++j) {
					if(a[i] === a[j])
						a.splice(j--, 1)
				}
			}

			return a
		},
		nestedObjectValue(object, propertyPath){
			const propertyPathArray = propertyPath.split('.')
			let currentObject = object
			let currentVal = null

			for(let i in propertyPathArray) {

				if(propertyPathArray[i]) {
					currentObject = currentObject[propertyPathArray[i]]
				}

				currentVal = currentObject

				if(propertyPath.includes('startsAtTime') || propertyPath.includes('endsAtTime') || propertyPath.includes('created') || propertyPath.includes('changed') || propertyPath.includes('expires') || propertyPath.includes('nextPayment')) {
					const timestampAsString = new String(currentVal)

					if(timestampAsString.length === 10) {
						currentVal = currentVal * 1000
					}

					currentVal = this.$moment(currentVal).format('DD/MM/YYYY HH:mm')
				}
			}

			return currentVal
		},
		buildPropertyIndexFromObject(object) {
			let propertyIndex = []

			if(object === null) {
				return propertyIndex
			}

			const objectKeys = Object.keys(object)

			for(let n in objectKeys) {
				const objectKeyValue = objectKeys[n]

				if(object[objectKeyValue] === Object(object[objectKeyValue]) && !propertyIndex.includes(objectKeyValue)) {
					let nestedObjectIndex = this.buildPropertyIndexFromObject(object[objectKeyValue])

					if(nestedObjectIndex) {
						nestedObjectIndex = nestedObjectIndex.map(item => objectKeyValue + '.' + item)
					}

					propertyIndex = this.arrayUnique(propertyIndex.concat(nestedObjectIndex))
				}
				else if(Array.isArray(object[objectKeyValue])) {
					for(let o in object[objectKeyValue]) {
						let nestedObjectIndex = object[objectKeyValue][o]

						if(nestedObjectIndex) {
							nestedObjectIndex = nestedObjectIndex.map(item => objectKeyValue + '.' + item)
						}

						propertyIndex = this.arrayUnique(propertyIndex.concat(nestedObjectIndex))
					}
				}
				else {
					propertyIndex.push(objectKeyValue)
				}
			}

			return propertyIndex
		},
		buildPropertyIndex() {
			let columnIndex = []

			for(let i in this.exportObjects) {
				const object = this.exportObjects[i]
				const objectIndex = this.buildPropertyIndexFromObject(object)
				columnIndex = this.arrayUnique(columnIndex.concat(objectIndex))
			}

			this.columns = columnIndex
			this.selectedColumns = []
		},
		exportHeaders() {
			let headers = '<thead>'

			for(let i in this.selectedColumns) {
				headers += '<th>'
				headers += this.selectedColumns[i]
				headers += '</th>'
			}

			headers += '</thead>'

			return headers
		},
		exportColumnValues(object) {
			const values = []

			for(let n in this.selectedColumns) {
				const columnProp = this.selectedColumns[n]
				let value = ''

				try {
					value = this.nestedObjectValue(object, columnProp)
				}
				catch(error) {
					// Do nothing
				}

				values.push(value)
			}

			return values
		},
		exportBody() {

			let body = '<tbody>'

			for(let i in this.exportObjects) {
				const object = this.exportObjects[i]

				body += '<tr>'

				for(let n in this.selectedColumns) {
					const columnProp = this.selectedColumns[n]
					body += '<td>'
					let value = ''

					try {
						value = this.nestedObjectValue(object, columnProp)
					}
					catch(error) {
						// Do nothing
					}

					body += value
					body += '</td>'
				}

				body += '</tr>'
			}

			body += '</tbody>'

			return body
		},
		createCSVExport() {
			const data = []
			const headers = []

			if (Array.isArray(this.selectedColumns)) {

				for (const i in this.selectedColumns) {
					const property = this.selectedColumns[i]
					headers.push(property)

					for(let j in this.exportObjects) {
						const object = this.exportObjects[j]
						if( !Array.isArray(data[j]) ) {
							data[j] = []
						}

						let value = null
						try {
							value = this.nestedObjectValue(object, property)
						}
						catch(error) {
							// Do nothing
						}

						if ( typeof value !== 'undefined' && value !== null ) {
							data[j][i] = value
						}
					}
				}
			}

			data.unshift(headers)
			return data
		},
		exportCSV() {
			const data = this.createCSVExport()
			let csvContent = "data:text/csv;charset=utf-8," + data.map(e => e.join(',')).join('\n')
			let encodeUri = encodeURI(csvContent)
			window.open(encodeUri)
		},
		exportFile() {
			this.exporting = true
			const html = this.exportData

			if ( this.selectedExportFormat === 'csv') {
				this.exportCSV()
			}

			else {
				this.$store.commit('exportXLS', {
					fileName: 'export',
					html: html
				})
			}


			setTimeout(() => {
				this.exporting = false
			}, 2000)
		}
	},
	watch: {
		selectAll(newValue, oldValue) {
			if (newValue === true) {
				this.selectAllProperties()
			}else {
				this.deSelectAllProperties()
			}
		}
	},
	mounted() {
		this.buildPropertyIndex()
	}
}
</script>
