<template>
  <v-navigation-drawer
    app
    :width="drawerWidth"
    :style="drawerStyle"
    disable-resize-watcher
    :permanent="!$vuetify.breakpoint.mobile"
    v-model="showSidebar"
    v-if="showSidebar"
  >
    <v-row
      :style="drawerStyle"
      class="fill-height pa-0"
      no-gutters
    >
      <v-navigation-drawer
        class="navigation"
        :mini-variant="!$vuetify.breakpoint.mobile"
        mini-variant-width="76px"
        v-if="!inIframe"
        disable-resize-watcher
        permanent
      >
        <template slot="prepend">
          <v-list class="pt-0 pb-0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  link
                  :to="'/dashboard'"
                  v-bind="attrs"
                  active-class="not-active"
                  v-on="on"
                >
                  <v-list-item-icon>
                    <v-img
                      contain
                      max-height="36px"
                      src="/salescloud_logo.svg"
                    />
                  </v-list-item-icon>
                </v-list-item>
              </template>
              <span>SalesCloud</span>
            </v-tooltip>
          </v-list>
        </template>
        <v-list
          class="pt-0 skeleton-loader"
          v-if="navigationItems.length === 0"
        >
          <template v-for="index in 10">
            <v-skeleton-loader
              :key="index"
              class="mx-auto"
              type="list-item-avatar"
            />
            <v-divider
              inset
              :key="index + 'div'"
            />
          </template>
        </v-list>

        <v-list
          class="pt-0 sidebar-navigation-items ml-2 mr-2"
          v-if="navigationItems.length > 0"
          dense
        >
          <template v-for="(item) in navigationItems">
            <v-tooltip
              :key="'/' + item.path"
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  link
                  :to="'/' + item.path"
                  :key="'/' + item.path"
                  v-bind="attrs"
                  active-class="primary--text"
                  class="main-navigation-item mb-1 mt-1"
                  v-on="on"
                >
                  <v-list-item-content>
                    <v-icon size="20px">
                      mdi-{{ item.icon }}
                    </v-icon>
                    <v-list-item-title v-if="false">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </template>
        </v-list>

        <template v-slot:append>
          <v-divider />
          <v-btn
            small
            block
            depressed
            class="transparent"
            @click="minimizeSidebar = !minimizeSidebar"
          >
            <v-icon
              small
              v-if="!minimizeSidebar"
            >
              mdi-arrow-collapse-left
            </v-icon>
            <v-icon
              small
              v-else
            >
              mdi-arrow-expand-horizontal
            </v-icon>
          </v-btn>
          <div class="text-center mt-4">
            <v-btn
              text
              depressed
              class="pa-0"
              x-large
              @click="showUserNavigation"
              style="min-width: 100%"
            >
              <v-avatar
                color="primary"
                size="56"
                v-if="user !== null"
                :style="avatarStyle"
              >
                {{ user.displayName.getInitials() }}
              </v-avatar>
            </v-btn>
          </div>
          <div class="text-center mt-2">
            <v-btn
              text
              depressed
              class="pa-0"
              x-large
              @click="showOrganizationNavigation"
              style="min-width: 100%"
            >
              <v-avatar
                color="primary"
                v-if="organization !== null"
                :style="avatarStyle"
              >
                <v-img
                  aspect-ratio="1"
                  max-width="40px"
                  contain
                  class="white"
                  v-if="organizationLogo"
                  :alt="organization.label"
                  :src="organizationLogo"
                />
              </v-avatar>
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
      <v-list
        style="width: 214px"
        class="pt-0"
        v-if="appBarTabs !== null && appBarTabs.length > 0 && !minimizeSidebar && !this.$vuetify.breakpoint.mobile"
      >
        <div
          class="mx-2"
          v-if="appBarFab !== null && appBarFab !== undefined"
        >
          <v-btn
            block
            color="primary"
            v-if="appBarFab"
            @click="appBarFab.callback"
            style="max-width: 200px"
          >
            <div v-if="appBarFab.title">
              {{ appBarFab.title }}
            </div>
            <div v-else>
              {{ $t('createNew') }}
            </div>
          </v-btn>
        </div>

        <div
          v-if="Array.isArray(appBarFabs) && appBarFabs.length > 0"
        >
          <template v-for="(fab, index) in appBarFabs">
            <v-btn
              v-if="fab"
              :key="index"
              :class="(index < (appBarFabs.length - 1)) ? 'mb-1' : ''"
              block
              color="primary"
              style="max-width: 200px"
              @click="fab.callback"
            >
              <div v-if="fab.title">
                {{ fab.title }}
              </div>
            </v-btn>
          </template>
        </div>

        <v-list-group
          class="pt-0"
          :value="true"
        >
          <template v-slot:activator>
            <v-list-item-title

              v-if="
                currentNavigationItem !== null &&
                  typeof currentNavigationItem !== 'undefined'
              "
            >
              <strong>{{ currentNavigationItem.title }}</strong>
            </v-list-item-title>
            <v-list-item-title v-else-if="$route.name">
              {{ $route.name }}
            </v-list-item-title>
          </template>

          <v-list-item
            active-class="primary--text"
            @click="selectedAppBarTab = appBarTab"
            v-for="appBarTab in appBarTabs"
            :key="appBarTab.key"
            dense
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ appBarTab.title }}
                <span
                  v-if="appBarTab.badge && appBarTab.badge.content"
                >({{ appBarTab.badge.content }})</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <div class="mx-2">
          <v-btn
            depressed
            block
            outlined
            color="grey"
            v-if="appBarExport"
            @click="appBarExport.callback"
          >
            {{ $t('export') }}
          </v-btn>
        </div>
      </v-list>
      <v-list
        class="current-navigation-item"
        v-else-if="
          currentNavigationItem !== null &&
            typeof currentNavigationItem !== 'undefined' &&
            currentNavigationItem.routes.length > 0 &&
            !minimizeSidebar &&
            !this.$vuetify.breakpoint.mobile
        "
        style="width: 214px; height: 100px"
      >
        <template v-for="route in currentNavigationItem.routes">
          <v-list-group
            :value="true"
            :key="route.path"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ route.title }}</v-list-item-title>
            </template>
          </v-list-group>
        </template>
      </v-list>
    </v-row>
    <v-dialog
      origin="left"
      content-class="user-navigation"
      v-model="showUserNavigationDialog"
    >
      <v-card
        max-width="500px"
        max-height="300px"
        flat
      >
        <v-row align="start">
          <v-col
            cols="6"
            style="border-right: 1px solid #ddd"
          >
            <div class="text-subtitle-2 font-weight-black ml-3">
              <v-autocomplete
                :disabled="switchingOrg"
                :loading="switchingOrg"
                v-model="selectedOrganization"
                dense
                outlined
                hide-details
                :items="accessibleOrganizations"
                item-value="uuid"
                item-text="label"
              />
            </div>
            <v-list dense>
              <v-list-item link>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('settings') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/company/channels"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t("channels") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/company/devices"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t('devices') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/company/messages"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t('messages') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/team"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t('team') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                target="_blank"
                link
                href="https://appstore.salescloud.is"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t('salescloud-apps') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6">
            <div class="text-subtitle-2 font-weight-black mr-3">
              <v-select
                dense
                outlined
                hide-details
                :items="languages"
                v-model="selectedLanguage"
                item-text="name"
                item-value="key"
              />
            </div>
            <div class="text-subtitle-2 font-weight-black mt-2">
              {{ user.displayName }}
            </div>
            <v-list dense>
              <v-list-item link>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('my-settings') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                @click="logout"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
export default {
	name: "Sidebar",
	data() {
		return {
			showUserNavigationDialog: false,
			switchingOrg: false,
		}
	},
	methods: {
    logout() {
      this.$store.dispatch('logout')
      this.$router.push({path: '/login'})
    },
		showOrganizationNavigation() {
			this.showUserNavigationDialog = true
		},
		showUserNavigation() {
			this.showUserNavigationDialog = true
		},
	},
	watch: {
		selectedOrganization(org) {
			this.switchingOrg = true
			this.$store.dispatch("getInitialData").finally(() => {
				this.switchingOrg = false
			})
		}
	},
	computed: {
    showSidebar: {
      set: function (newValue) {
        this.$store.commit("updateShowSidebar", newValue)
      },
      get: function () {
        return this.$store.state.showSidebar
      },
    },
		minimizeSidebar: {
			get() {
				return this.$store.state.minimizeSidebar
			},
			set(value) {
				this.$store.commit("setMinimizeSidebar", value)
			},
		},
		drawerWidth() {

			if (this.inIframe) {
        if(this.$route.name === 'Bookings 2.0' || this.$route.name === 'Calendar') {
          return '0'
        }
				return "210px"
			}

			if (this.minimizeSidebar) {
				return "76px"
			}

      if(this.$vuetify.breakpoint.mobile) {
        return '210px'
      }

			return "300px"
		},
    accessibleOrganizations() {
      const organizations = this.$store.state.user.accessibleOrganizations
      if(Array.isArray(organizations) && organizations.length > 0) {
        return organizations.sort((a,b) => {
          if(a.label > b.label) {
            return 1
          }
          if(a.label < b.label) {
            return -1
          }
          return 0
        })
      }
      return []
    },
		selectedOrganization: {
			get() {
				return this.$store.state.selectedOrganization
			},
			set(value) {
        if(value) {
          this.$store.commit('updateSelectedOrganization', value)
        }
			},
		},
    selectedLanguage: {
      get() {
        return this.$store.state.userLanguage
      },
      set(value) {
        this.$store.commit('setUserLanguage', value)
        this.$store.dispatch('updateUserLanguage', value)
      }
    },
		drawerStyle() {

			if (this.inIframe) {
				return {}
			}

			return {
				borderLeft: "5px solid " + this.primaryColor,
			}
		},
		inIframe() {
			return this.$store.state.inIframe
		},
		avatarStyle() {
			return {
				borderColor: this.primaryColor,
				borderWidth: "3px",
				borderStyle: "solid",
			}
		},
		user() {
			return this.$store.state.user
		},
		organization() {
			return this.$store.state.organization
		},
		organizationLogo() {
			if (
				this.$store.state.organization.logo === null ||
				this.$store.state.organization.logo === undefined
			) {
				return null
			}

			if (
				this.$store.state.organization.logo.file === null ||
				this.$store.state.organization.logo.file === undefined
			) {
				return null
			}

			return this.$store.state.organization.logo.file.sources.large
		},
		selectedAppBarTab: {
			set(appBarTab) {
				this.$store.commit("updateSelectedAppBarTab", appBarTab.key)
			},
			get() {
				return this.$store.state.selectedAppBarTab
			},
		},
		appBarTabs() {
			return this.$store.state.appBarTabs
		},
		userIsLoggedIn() {
			return this.$store.getters.isLoggedIn
		},
		navigationItems() {
			return this.$store.getters.navigationItems
		},
		primaryColor() {
			return this.$store.getters.primaryColor
		},
		currentNavigationItem() {
			return this.$store.getters.navigationItems.find(
				(navItem) => navItem.path === this.$route.path.substr(1)
			)
		},
		appBarFab() {
			return this.$store.state.appBarFAB
		},
    appBarFabs() {
      return this.$store.state.appBarFABs
    },
		appBarExport() {
			return this.$store.state.appBarExport
		},
    languages() {
      return this.$store.state.languages
    }
	}
}
</script>

<style>
.user-navigation {
	align-self: flex-end;
	left: 0;
	box-shadow: none;
}

.main-navigation-item:before {
	border-radius: 6px;
}
</style>
