<template>
  <v-dialog
    value="true"
    max-width="800px"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title>{{$t('batchProcess')}}</v-card-title>
      <v-card-subtitle>{{$t('youAreAboutToProcessLengthItems', {itemsLength: this.bulkOperation.payload.length })}}</v-card-subtitle>
      <v-card-text
        class="pt-4"
      >
        <v-progress-linear
          :value="progress"
          height="25"
        >
          <strong>{{ Math.ceil(progress) }}%</strong>
        </v-progress-linear>
      </v-card-text>
      <!-- <v-card-actions class="text-center align-center">
        <v-btn
          color="error"
          text
          class="mx-auto pa-5"
          :disabled="executing"
          :loading="executing"
          @click="close"
          v-if="progress < 100"
        >
          Abort
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          class="mx-auto pa-5"
          :disabled="executing"
          :loading="executing"
          @click="executeBulkOperation"
          v-if="progress < 100"
        >
          Run process
        </v-btn>
        <v-btn
          color="error"
          class="mx-auto pa-5"
          @click="close"
        >
          Close
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
	export default {
		name: "BulkOperationDialog",
    data: function () {
      return {
        progress: 0,
        executing: false
      }
    },
    methods: {
      executeBulkOperation: async function() {
        this.executing = true
        let errorCount = 0
        const payloadSize = this.bulkOperation.payload.length

		    for(let i = 0; i < this.bulkOperation.payload.length; i++){
		      const item = this.bulkOperation.payload[i]
          
          if(errorCount > 5) {
            break
          }
          
          try {
            await this.$store.dispatch('executeBulkOperation', {
              action: this.bulkOperation.action,
              route: this.bulkOperation.route,
              uiValues: this.bulkOperation.uiValues,
              payload: item
            }).then(res => {
              this.addProgress()
              if(!res || !res.success) {
                errorCount++
              }
            })
          } catch (error) {
            errorCount++
            console.error(`[BulkOperationDialog] Error #${errorCount} executing bulk operation`, error)
          }
        }
        setTimeout(() => {
          this.$emit('close')

        if(errors === 0) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: this.$t('bulkOperationComplete'),
          })
        } else if (errors > 0 && errors < payloadSize) {
          this.$store.commit('updateActionError', {
            message: this.$t('warning'),
            subMessage: this.$t('bulkOperationCompleteWithSomeErrors(Errors)', {errors: errors}),
          })
        } else {
          this.$store.commit('updateActionError', {
            message: this.$t('failure'),
            subMessage: this.$t('bulkOperationsFailedToExecute'),
          })
        }
        this.executing = false
        }, 500)

      },
      addProgress: function () {
        this.progress = this.progress + (1 / ( this.bulkOperation.payload.length ) * 100)
      },
      close() {
        this.$emit('close')
      }
    },
    computed: {
      bulkOperation() {
        return this.$store.state.bulkOperation
      }
    },
    mounted() {
      this.executeBulkOperation()
    }
	}
</script>

<style scoped>

</style>
