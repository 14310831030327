<template>
  <div>
    <v-text-field
      filled
      v-if="value.type === 'email'"
      dense
      :label="value.label"
      v-model="value.value"
      :clearable="value.clearable"
      :hide-details="value.hideDetails"
      @change="change"
      :disabled="value.disabled || disabled"
      :persistent-hint="value.persistentHint"
      :hint="value.hint"
    />
    <v-text-field
      fileld
      v-if="value.type === 'phone'"
      dense
      :label="value.label"
      v-model="value.value"
      :clearable="value.clearable"
      :hide-details="value.hideDetails"
      @change="change"
      :disabled="value.disabled || disabled"
      :persistent-hint="value.persistentHint"
      :hint="value.hint"
    />
    <v-text-field
      filled
      v-if="value.type === 'number'"
      type="number"
      dense
      :label="value.label + (value.required ? ' *' : '')"
      v-model="value.value"
      :clearable="value.clearable"
      :hide-details="value.hideDetails"
      @change="change"
      :disabled="value.disabled || disabled"
      :persistent-hint="value.persistentHint"
      :hint="value.hint"
      :rules="value.rules"
      :suffix="value.suffix"
      :min="value.min"
    />
    <v-text-field
      filled
      v-if="value.type === 'text'"
      dense
      :label="value.label + (value.required ? ' *' : '')"
      v-model="value.value"
      :clearable="value.clearable"
      :hide-details="value.hideDetails"
      @change="change"
      :disabled="value.disabled || disabled"
      :rules="value.rules"
      :persistent-hint="value.persistentHint"
      :hint="value.hint"
    />
    <v-textarea
      filled
      v-if="value.type === 'textarea'"
      dense
      :label="value.label + (value.required ? ' *' : '')"
      v-model="value.value"
      :clearable="value.clearable"
      :hide-details="value.hideDetails"
      @change="change"
      :disabled="value.disabled || disabled"
      :rules="value.rules"
      :persistent-hint="value.persistentHint"
      :hint="value.hint"
    />
    <v-text-field
      filled
      v-if="value.type === 'textfield'"
      dense
      :label="value.label + (value.required ? ' *' : '')"
      v-model="value.value"
      :clearable="value.clearable"
      :hide-details="value.hideDetails"
      @change="change"
      :disabled="value.disabled || disabled"
      :rules="value.rules"
      :persistent-hint="value.persistentHint"
      :hint="value.hint"
    />
    <v-select
      filled
      v-if="value.type === 'select'"
      :dense="!value.multiple"
      :label="value.label + (value.required ? ' *' : '')"
      :items="value.options"
      item-text="label"
      item-value="key"
      v-model="value.value"
      :hide-details="value.hideDetails"
      @change="change"
      :disabled="value.disabled || disabled"
      :multiple="value.multiple"
      :small-chips="value.multiple"
      :deletable-chips="value.multiple"
      :rules="value.rules"
      :persistent-hint="value.persistentHint"
      :hint="value.hint"
      :clearable="value.clearable"
      :no-data-text="value.noDataText"
    >
      <template
        v-if="value.primary"
        v-slot:item="{ item, on, attrs }"
      >
        <v-list-item
          v-on="on"
          v-bind="attrs"
          :class="item.fullItem[value.primary] ? 'text--primary' : 'text--secondary'"
        >
          <v-list-item-icon>
            <v-icon v-if="value.value.includes(item.key)">
              mdi-checkbox-marked
            </v-icon>
            <v-icon v-else>
              mdi-checkbox-blank-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <span>
              <span :style="item.fullItem[value.primary] ? 'font-weight: 700;' : 'font-weight: 400;'">
                {{ item.label }}
              </span>
              <span
                v-if="item.fullItem[value.primary]"
                style="font-size: 14px; font-weight: 400;"
              >
                ({{ value.primary }})
              </span>
            </span>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
    <v-autocomplete
      filled
      v-if="value.type === 'autocomplete'"
      :dense="!value.multiple"
      :label="value.label + (value.required ? ' *' : '')"
      :items="value.options"
      item-text="label"
      item-value="key"
      v-model="value.value"
      :hide-details="value.hideDetails"
      @change="change"
      :disabled="value.disabled || disabled"
      :multiple="value.multiple"
      :small-chips="value.multiple"
      :deletable-chips="value.multiple"
      :rules="value.rules"
      :persistent-hint="value.persistentHint"
      :hint="value.hint"
      :clearable="value.clearable"
      :no-data-text="value.noDataText"
    >
      <template
        v-if="value.primary"
        v-slot:item="{ item, on, attrs }"
      >
        <v-list-item
          v-on="on"
          v-bind="attrs"
          :class="item.fullItem[value.primary] ? 'text--primary' : 'text--secondary'"
        >
          <v-list-item-icon>
            <v-icon v-if="value.value.includes(item.key)">
              mdi-checkbox-marked
            </v-icon>
            <v-icon v-else>
              mdi-checkbox-blank-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <span>
              <span :style="item.fullItem[value.primary] ? 'font-weight: 700;' : 'font-weight: 400;'">
                {{ item.label }}
              </span>
              <span
                v-if="item.fullItem[value.primary]"
                style="font-size: 14px; font-weight: 400;"
              >
                ({{ value.primary }})
              </span>
            </span>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <v-checkbox
      v-if="value.type === 'boolean'"
      :label="value.label"
      v-model="value.value"
      hide-details
      @change="change"
      :disabled="value.disabled || disabled"
      :persistent-hint="value.persistentHint"
      :hint="value.hint"
    />
    <v-checkbox
      v-if="value.type === 'checkbox'"
      :label="value.label"
      v-model="value.value"
      hide-details
      @change="change"
      :disabled="value.disabled || disabled"
      :persistent-hint="value.persistentHint"
      :hint="value.hint"
    />
    <v-switch
      v-if="value.type === 'switch'"
      :class="inCol ? 'mt-0' : ''"
      :label="value.label"
      v-model="value.value"
      :hide-details="value.hideDetails"
      @change="change"
      :disabled="value.disabled || disabled"
      :persistent-hint="value.persistentHint"
      :hint="value.hint"
    />
    <date-selection
      v-if="value.type === 'calendar'"
      :label="value.label"
      v-model="value.value"
      @change="change"
      :disabled="value.disabled || disabled"
    />
    <date-selection
      v-if="value.type === 'date'"
      filled
      clearable
      :label="value.label"
      v-model="value.value"
      :disabled="value.disabled || disabled"
      :hide-details="value.hideDetails"
      :persistent-hint="value.persistentHint"
      :hint="value.hint"
      :multiple="value.multiple"
      @input="change"
    />
    <multiple-dates-selection
      v-if="value.type === 'multiple-dates'"
      filled
      clearable
      :label="value.label"
      v-model="value.value"
      :disabled="value.disabled || disabled"
      :hide-details="value.hideDetails"
      :persistent-hint="value.persistentHint"
      :hint="value.hint"
      :multiple="value.multiple"
      :no-icon="value.noIcon"
      @input="change"
    />
    <color-select
      v-if="value.type === 'color'"
      dense
      filled
      clearable
      :label="value.label"
      v-model="value.value"
      :disabled="value.disabled || disabled"
      :hide-details="value.hideDetails && !value.persistentHint"
      :hint="value.hint"
      @input="change"
    />
  </div>
</template>

<script>
import DateSelection from '../components/common/DateSelection.vue'
import ColorSelect from "@/components/common/ColorSelect"
import MultipleDatesSelection from "@/components/common/MultipleDatesSelection"

export default {
    name: 'UiField',
    components: {
      MultipleDatesSelection,
      ColorSelect,
      DateSelection
    },
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      disabled: {
        type: Boolean,
        default: false
      },
      inCol: {
        type: Boolean,
        default: false
      }
    },
  methods: {
    change(e) {
      const field = this.value
      this.$emit('change', { value: e, field })
    }
  }
}
</script>
