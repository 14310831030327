<template>
  <v-dialog
    value="true"
    v-if="actionError !== null"
    max-width="500"
    @click:outside="close"
  >
    <v-card
      outlined
      class="dialog-card-style"
    >
      <v-card-title class="justify-center">
        <v-icon
          dark
          size="64"
          color="error"
        >
          mdi-alert-circle-outline
        </v-icon>
      </v-card-title>
      <v-card-text class="title text-center font-weight-bold">
        {{ actionError.message }}
      </v-card-text>
      <v-card-text
        v-if="actionError.subMessage"
        v-html="actionError.subMessage"
        class="text-center font-weight-bold"
        style="white-space: pre-wrap"
      />
      <v-card-actions>
        <v-container class="pt-0 pb-0">
          <v-row>
            <v-col
              cols="12"
              :sm="actionError.callback !== null && typeof actionError.callback !== 'undefined' ? '6' : '12'"
            >
              <v-btn
                block
                @click="close"
                outlined
                rounded
                class="close-button-style"
              >
                {{ $t('close') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              v-if="actionError.callback !== null && typeof actionError.callback !== 'undefined'"
            >
              <v-btn
                block
                @click="callbackFunction"
              >
                {{ actionError.callbackLabel !== null && typeof actionError.callbackLabel !== 'undefined' ? actionError.callbackLabel : 'Try again' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
	export default {
		name: "ActionError",
    methods: {
      close() {
        this.$emit('closed')
      },
      callbackFunction() {
        this.$emit('callback')
        this.actionError.callback()
        this.$store.commit('updateActionError', null)
      }
    },
    computed: {
      actionError() {
        return this.$store.state.actionError
      },
			computedCardStyle() {
				return {
					borderColor: 'black',
					borderWidth: '1px'
				}
			}
		}
  }
</script>

<style scoped>
.close-button-style {
	background-color: #00000020;
	border-color: black;
	color: black;
}

.dialog-card-style {
		border-color: black;
		border-width: 1px;
	}
</style>
