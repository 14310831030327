<template>
  <div>
    <v-tab
      :href="'#' + tab.key"
      v-if="tab.key==='add'"
      style="height: 48px"
    >
      <div v-if="!tab.badge || !tab.badge.content">
        <v-icon>
          mdi-card-plus-outline
        </v-icon> 
      </div>
      <v-badge
        v-else
        :color="tab.badge.color"
        :content="tab.badge.content"
      >
        {{ tab.title }}
      </v-badge>
    </v-tab>

    <v-tab
      :href="'#' + tab.key"
      v-else
      style="height: 48px"
    >
      <div v-if="!tab.badge || !tab.badge.content">
        {{ tab.title }}
      </div>
      <v-badge
        v-else
        :color="tab.badge.color"
        :content="tab.badge.content"
      >
        {{ tab.title }}
      </v-badge>
    </v-tab>
  </div>
</template>

<script>
export default {
	name: 'AppBarTab',
  props: {
    tab: {
      type: Object, 
      default: () => {}
    }
  },
	data: () => ({
	})
}
</script>
