/* eslint-disable vue/no-async-in-computed-properties */
<template>
  <v-dialog
    v-model="showContextOverlay"
    absolute
    right
    min-width="400px"
    max-width="50%"
    content-class="context-overlay"
    attach="#context-overlay"
    @click:outside="closeOverlay"
    style="height: 100% !important;"
    transition="slide-x-transition"
    origin="top right"
    :fullscreen="isFullscreen"
    id="context-overlay"
  >
    <component 
      v-if="dynamicComponent !== null"
      :is="dynamicComponent"
      v-bind="props"
      @close="closeOverlay"
    />
  </v-dialog>
</template>

<script>

export default {
    name: 'ContextOverlay',
    props: {
        component: {
            type: String,
            required: true
        },
        props: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showContextOverlay: true
        }
    },
    computed: {
        isFullscreen() {
            return this.$vuetify.breakpoint.xs === true
        },
        dynamicComponent() {
            return () => import(`@/${this.component}.vue`)
        }
    },
    methods: {
        closeOverlay() {
            this.$store.commit('updateContextOverlay', null)
            this.showContextOverlay = false
        }
    }
}
</script>

<style>
#context-overlay .v-dialog__content {
    justify-content: flex-end;
}

#context-overlay .context-overlay {
    height: 100% !important;
    max-height: 100% !important;
    background: #efefef;
    margin: 0;
}
</style>