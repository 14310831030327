<template>
  <v-dialog
    value="true"
    persistent
    max-width="800px"
    scrollable
  >
    <v-card class="overflow-x-hidden">
      <v-alert
        v-if="showError"
        type="error"
        icon="mdi-alert"
        class="white--text mb-0"
        rounded="false"
        tile
      >
        {{ errorMessage }}
      </v-alert>
      <v-card-title>{{ $t('batchProcess') }}</v-card-title>
      <v-card-subtitle>{{ $t('youAreAboutToProcess') }} {{ this.badgeProcess.items.length }} {{ $t('items') }}.</v-card-subtitle>
      <v-card-text
        v-if="progress === 0"
        class="pa-0"
      >
        <v-row class="px-4 mx-0 grey lighten-2">
          <v-col cols="12">
            <p
              v-for="(item, index) in this.badgeProcess.items"
              :key="index"
            >
              {{ item.title || item.name || item.label }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text
        v-else
        class="pt-4"
      >
        <v-progress-linear
          :value="progress"
          height="25"
        >
          <strong>{{ Math.floor(progress) }}%</strong>
        </v-progress-linear>
      </v-card-text>
      <v-card-actions class="text-center align-center">
        <v-btn
          v-if="progress < 99"
          color="error"
          text
          class="mx-auto pa-5"
          @click="close"
        >
          {{ $t('abort') }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="progress === 0"
          color="primary"
          class="mx-auto pa-5"
          :disabled="processing || badgeProcess.items.length <= 0"
          :loading="processing"
          @click="runProcess"
        >
          {{ $t('runProcess') }}
        </v-btn>
        <v-btn
          v-if="progress > 99"
          color="error"
          class="mx-auto pa-5"
          @click="close"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "BadgeProcess",
    data: function () {
      return {
        progress: 0,
        processing: false,
        showError: false,
        errorMessage: ""
      }
    },
    computed: {
      badgeProcess() {
        return this.$store.state.badgeProcess
      }
    },
    methods: {
      close() {
        this.$emit('closed')
      },
      runProcess: async function () {
        this.processing = true
        this.progress += 0.001

        if(this.badgeProcess.items.length <= 0) {
          this.processing = false
          this.showError = true
          this.errorMessage = this.$t('noItemsSelectedToProcess')
          return
        }

        let promisesArray = []

        for(let i = 0; i < this.badgeProcess.items.length; i++) {
          promisesArray.push(new Promise((resolve, reject) => {
            this.badgeProcess.process({
              item: this.badgeProcess.items[i],
              settings: this.badgeProcess.settings,
              store: this.$store
            }).then(response => {
              if(response !== null && typeof response !== 'undefined' && response.success) {
                this.addProgress()
                resolve(response)
              }
              reject(this.$t('responseIsUnsuccessfulOrInvalid'), response)
            })
          }))
        }

        Promise.all(promisesArray).then(() => {
          if(this.badgeProcess.onFinish !== null && typeof this.badgeProcess.onFinish !== 'undefined') {
						this.badgeProcess.onFinish()
          }
        }).catch((err) => {
          this.showError = true
          this.errorMessage = err.message
        }).finally(() => {
          this.processing = false
        })
      },
      addProgress: function () {
        this.progress = this.progress + (1 / ( this.badgeProcess.items.length ) * 100)
      }
    }
  }
</script>

<style scoped>

</style>
