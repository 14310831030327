<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
    max-width="500"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-text-field
        v-on="on"
        v-bind="attrs"
        :label="label"
        :placeholder="$t('clickHereToSelectAColor')"
        v-model="colorHexValue"
        :disabled="processing || disabled"
        :persistent-hint="!!hint"
        :prepend-icon="prependIcon ? 'mdi-palette' : ''"
        :hint="hint"
        :clearable="clearable"
        :filled="filled"
        :outlined="outlined"
        :dense="dense"
        :hide-details="hideDetails"
        @click:clear="clearColor"
      >
        <template v-slot:append-outer>
          <v-icon
            v-if="colorHexValue"
            :color="colorHexValue"
          >
            mdi-circle
          </v-icon>
          <v-icon v-else>
            mdi-circle-outline
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <div class="white">
      <v-color-picker
        width="500"
        mode="hexa"
        hide-mode-switch
        @input="selectColor"
      />
      <v-btn
        v-if="applyManually"
        outlined
        block
        @click="apply"
      >
        {{ $t('apply') }}
      </v-btn>
    </div>
  </v-menu>
</template>

<script>
export default {
	name: "ColorSelect",
	props: {
		value: {
      type: String,
      default: ''
    },
		processing: {
      type: Boolean,
    },
		label: {
      type: String,
      default: ''
    },
		hint: {
      type: String,
      default: ''
    },
		clearable: {
      type: Boolean,
    },
		prependIcon: {
      type: Boolean,
    },
		filled: {
      type: Boolean,
    },
		disabled: {
      type: Boolean
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    }, 
    applyManually: {
      type: Boolean, 
      default: false
    }
	},
	data() {
		return {
			colorHexValue: null,
      color: null
		}
	},
	watch: {
		value(value) {
			this.colorHexValue = value
		}
	},
	methods: {
		selectColor(color) {
      this.color = color
      if(!this.applyManually) {
        this.apply()
      }
		},
    apply() {
      this.$emit('input', this.color.hex)
    },
    clearColor() {
      this.$emit('input', null)
    }
	},
  beforeMount() {
    this.colorHexValue = this.value
  },
  beforeDestroy() {
		this.colorHexValue = null
	}
}
</script>

<style scoped>

</style>
