<template>
  <v-menu
    ref="selectDates"
    v-model="dateMenu"
    :close-on-content-click="true"
    offset-y
    transition="scale-transition"
    :max-width="datePickerWidth"
  >
    <template v-slot:activator="{on, attr}">
      <v-select
        multiple
        v-bind="attr"
        v-on="on"
        :label="label"
        :filled="filled"
        :dense="dense"
        :placeholder="$t('pleaseSelectDates')"
        :prepend-icon="noIcon ? '' : (icon ? icon : 'mdi-calendar')"
        :hide-details="hideDetails"
        :disabled="disabled"
        :outlined="outlined"
        :clearable="clearable"
        :persistent-hint="persistentHint"
        :hint="hint"
        chips
        deletable-chips
        :value="selectedDates"
        :items="selectedDates"
        @click:clear="clearDates"
      />
    </template>
    <v-date-picker
      v-model="selectedDates"
      v-if="dateMenu"
      no-title
      :full-width="fullWidth"
      :width="width"
      :multiple="multiple"
      style="min-height: 350px"
      :min="minDate"
      :max="maxDate"
    />
  </v-menu>
</template>

<script>
export default {
	name: "MultipleDatesSelection",
	props: {
		value: {
			type: Array,
			default: null
		},
		label: {
			type: String,
			default: ''
		},
		dense: {
			type: Boolean,
		},
		filled: {
			type: Boolean,
		},
		required: {
			type: Boolean,
		},
		icon: {
			type: String,
			default: ''
		},
		hideDetails: {
			type: Boolean,
		},
		noIcon: {
			type: Boolean,
		},
		disabled: {
			type: Boolean,
		},
		outlined: {
			type: Boolean,
		},
		clearable: {
			type: Boolean,
		},
		noCurrentDayPreload: {
			type: Boolean
		}, 
		minDate: {
			type: String,
			default: ''
		},
		maxDate: {
			type: String,
			default: ''
		}, 
		width: {
			type: Number, 
			default: 350
		},
		fullWidth: {
			type: Boolean,
			default: true
		},
    multiple: {
      type: Boolean,
      default: true
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null
    }
	},
	data() {
		return {
			dateMenu: false,
			datePickerWidth: 500,
		}
	},
	mounted() {
    //
	},
	computed: {
		systemTimeInMillis() {
			return this.$store.getters.systemTime
		},
    selectedDates: {
      get() {
        const dates = []
        if(Array.isArray(this.value)) {
          for(let index = 0; index < this.value.length; index++) {
            const dateTimestamp = this.value[index]
            dates.push(new Date(dateTimestamp).toISOString().substring(0, 10))
          }
        }
        return dates
      },
      set(dates) {
        if(Array.isArray(dates)) {
          const timestamps = []
          for(let index = 0; index < dates.length; index++) {
            const dateString = dates[index]
            timestamps.push(new Date(dateString).getTime())
          }
          this.$emit('input', timestamps)
        } else {
          this.$emit('input', null)
        }
      }
    }
	},
	methods: {
		clearDates() {
			this.selectedDates = null
		}
	}
}
</script>

<style scoped>

</style>
