<template>
  <v-dialog
    :value="true"
    max-width="500px"
    :persistent="processing"
  >
    <v-card>
      <v-card-title>
        {{ callToAction.title }}
      </v-card-title>
      <v-card-subtitle>
        {{ callToAction.description }}
      </v-card-subtitle>

      <v-card-text
        v-if="callToAction.uiFields !== null && callToAction.uiFields !== undefined && callToAction.uiFields.length > 0"
      >
        <template
          v-for="(uiField, index) in callToAction.uiFields"
        >
          <uiField
            v-model="callToAction.uiFields[index]"
            :key="index"
          />
        </template>
      </v-card-text>

      <v-card-actions>
        <v-btn
          block
          color="success"
          large
          :loading="processing"
          :disabled="processing"
          @click="execute"
        >
          {{$t('process')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>



<script>
    import uiField from "@/components/uiField"

    export default {
        name: "CallToAction",
        props: ['callToAction'],
        components: {
            uiField
        },
        data() {
            return {
                processing: false,
            }
        },
        methods: {
            execute() {
                this.processing = true

                this.$store.dispatch("executeCallToAction", { action: this.callToAction, route: "", uiValues: this.callToAction.uiFields, payload: {} }).then(res => {
                    
                }).finally(() => {
                    this.processing = false
                    this.$store.commit('updateSelectedCallToAction', null)

                    if (res.success) {
                        this.$store.commit('updateActionSuccess', {
                            message: this.$t('success'),
                            subMessage: res.message,
                        })
                    }else {
                         this.$store.commit('updateActionError', {
                            message: this.$t('error'),
                            subMessage: res.message
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>