<template>
  <v-dialog
    value="true"
    v-if="actionSuccess !== null"
    max-width="500"
    @click:outside="close"
  >
    <v-card
      outlined
      class="dialog-card-style"
    >
      <v-card-title class="justify-center">
        <v-icon
          dark
          size="64"
          color="success"
        >
          mdi-check-circle-outline
        </v-icon>
      </v-card-title>
      <v-card-text class="title text-center font-weight-bold black--text">
        {{ actionSuccess.message }}
      </v-card-text>
      <v-card-text
        v-if="actionSuccess.subMessage !== null && typeof actionSuccess.subMessage !== 'undefined'"
        class="text-center font-weight-bold "
        style="white-space: pre-wrap"
      >
        {{ actionSuccess.subMessage }}
      </v-card-text>
      <v-card-actions>
        <v-container class="pt-0 pb-0">
          <v-row>
            <v-col
              cols="12"
              :sm="actionSuccess.callback !== null && typeof actionSuccess.callback !== 'undefined' ? '6' : '12'"
            >
              <v-btn
                block
                @click="close"
                outlined
                rounded
                :style="computedCloseButtonStyle"
              >
                {{ $t('close') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              v-if="actionSuccess.callback !== null && typeof actionSuccess.callback !== 'undefined'"
            >
              <v-btn
                block
                color="success darken-2"
                @click="callbackFunction"
              >
                {{ actionSuccess.callbackLabel !== null && typeof actionSuccess.callbackLabel !== 'undefined' ? actionSuccess.callbackLabel : 'Update more' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
	name: "ActionSuccess",
	methods: {
		close() {
			this.$emit('closed')
		},
    callbackFunction() {
      this.$emit('callback')
      this.actionSuccess.callback()
      this.$store.commit('updateActionSuccess', null)
    }
	},
	computed: {
    actionSuccess() {
			return this.$store.state.actionSuccess
		},
		computedCloseButtonStyle() {
			const primaryColor = this.primaryColor
			return {
				backgroundColor: `${primaryColor}20`,
				borderColor: primaryColor,
				color: primaryColor,
			}
		},
		primaryColor() {
			return this.$store.getters.primaryColor
		}
	}
}
</script>

<style scoped>
	.dialog-card-style {
		border-color: black;
		border-width: 1px;
	}
</style>
