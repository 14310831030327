import Dexie from 'dexie'

const getOrganizationDB = (organizationUUID) => {
    const db = new Dexie(organizationUUID)

    db.version(5).stores({
        payments: '&uuid,amount,namespace,currency_code,created',
        items: '&uuid,type,title,sku',
        customers: '&uuid,label,phone,email,ssn',
        invoices: '&uuid,invoiceNumber,orderId',
        formsOfPayment: '&uuid,created,remoteId'
    })

    return db
}

const getGlobalDB = () => {
	const db = new Dexie('global')

	db.version(5).stores({
			initialData: '&uuid,data'
	})

	return db
}

export default {
    getOrganizationDB,
		getGlobalDB
}
