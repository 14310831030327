import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Loading.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Loading',
    component: Home
  },
  {
    path: '/signup',
    name: 'Signup',
    meta: { title: `Signup` },
    component: () => import(/* webpackChunkName: "about" */ '../views/signup.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: `Login` },
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/reset/password',
    name: 'Reset Password',
    meta: { title: `Reset` },
    component: () => import(/* webpackChunkName: "about" */ '../views/reset-password.vue')
  },
  {
    path: '*',
    name: '404',
    meta: { title: `Page not found` },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]
let routerMode = 'hash'

if(window.location.host === 'app.salescloud.is' /* || window.location.host === 'admin-stage-jr7up4vf5q-ew.a.run.app' */ ) {
  routerMode = 'history'
}

const router = new VueRouter({
  base: process.env.BASE_URL,
	mode: routerMode,
  routes
})

export default router
